import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: '登录页',
    meta: {
      title: "用户登录-数字金融服务平台-商驿科技",
      index: 14,
      auth: true,
    },
    component: () => import('../views/login'),
  },
  {
    path: '/',
    name: 'Home',
    redirect: "/home",
    component: Home,
    children: [
      {
        path: '/home',
        name: '首页',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 1,
          auth: true,
        },
        component: () => import('@/views/body.vue'),
      },
      {
        path: 'business',
        name: 'business',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 2,
          auth: true,
        },
        component: () => import('@/views/FinancialService/BusinessChain.vue'),
      },
      {
        path: 'application',
        name: 'application',
        redirect: "/procedure1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 3,
          auth: true,
        },
        component: () => import('@/views/FinancialService/application.vue'),
        children: [
          {
            path: '/procedure1',
            name: 'procedure1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 4,
              auth: true,
            },
            component: () => import('@/views/FinancialService/procedure1.vue'),
          },
          {
            path: '/procedure2',
            name: 'procedure2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/FinancialService/procedure2.vue'),
          },
          {
            path: '/procedure3',
            name: 'procedure3',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 6,
              auth: true,
            },
            component: () => import('@/views/FinancialService/procedure3.vue'),
          },
          {
            path: '/procedure4',
            name: 'procedure4',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 7,
              auth: true,
            },
            component: () => import('@/views/FinancialService/procedure4.vue'),
          },
          {
            path: '/procedure5',
            name: 'procedure5',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 7,
              auth: true,
            },
            component: () => import('@/views/FinancialService/procedure5.vue'),
          },
        ],
      },
      {
        path: 'applyForMoney',
        name: 'applyForMoney',
        redirect: "/applyForMoney1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 3,
          auth: true,
        },
        component: () => import('@/views/FinancialService/applyForMoney/applyForMoney.vue'),
        children: [
          {
            path: '/applyForMoney1',
            name: 'applyForMoney1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 7,
              auth: true,
            },
            component: () => import('@/views/FinancialService/applyForMoney/applyForMoney1.vue'),
          },
          {
            path: '/applyForMoney2',
            name: 'applyForMoney2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 7,
              auth: true,
            },
            component: () => import('@/views/FinancialService/applyForMoney/applyForMoney2.vue'),
          },
        ]
      },

      {
        path: 'applyForMoneyDetails',
        name: 'applyForMoneyDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('@/views/FinancialService/applyForMoney/applyForMoneyDetails.vue'),
      },
      {
        path: 'businessTicket',
        name: 'businessTicket',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/shangPiao/BusinessTicket'),
      },
      // 询价页面
      {
        path: 'enquiry',
        name: 'enquiry',
        redirect: "/enquiryApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('../views/shangPiao/enquiry'),
        children: [
          {
            path: '/enquiryApply1',
            name: 'enquiryApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply1'),
          },
          {
            path: '/enquiryApply2',
            name: 'enquiryApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply2'),
          },
          {
            path: '/enquiryApply3',
            name: 'enquiryApply3',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 6,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply3'),
          },
          {
            path: '/enquiryPledgeApply',
            name: 'enquiryPledgeApply',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 6,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryPledgeApply'),
          },

        ],
      },
      // 商票询价、
      {
        path: 'immediatelyEnquiry',
        name: 'immediatelyEnquiry',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/shangPiao/immediatelyEnquiry'),
      },
      // 票据管理
      {
        path: 'billAdministration',
        name: '票据管理',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/shangPiao/billAdministration'),
      },

      // 跨境驿
      {
        path: 'crossBorder',
        name: 'crossBorder',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/crossBorder.vue'),
      },
      // 跨境关税保函产品申请
      {
        path: 'gsbhApplication',
        name: 'gsbhApplication',
        redirect: "/gsbhApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApplication'),
        children: [
          {
            path: '/gsbhApply1',
            name: 'gsbhApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApply1'),
          },
          // 关税保函确认授信申请
          {
            path: '/creditExtension',
            name: 'creditExtension',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/project/projectKuaJing/creditExtension.vue'),
          },
          {
            path: '/gsbhApply2',
            name: 'gsbhApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApply2'),
          },

        ],
      },

      // 跨境关税E保产品申请
      {
        path: 'borderApplication',
        name: 'borderApplication',
        redirect: "/crossBorderApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/BorderApplication.vue'),
        children: [
          {
            path: '/crossBorderApply1',
            name: 'crossBorderApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/crossBorderApply1'),
          },
          {
            path: '/crossBorderApply2',
            name: 'crossBorderApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/crossBorderApply2'),
          },

        ],
      },

      // 跨境关税保证保险产品申请
      {
        path: 'gsbzbxApplication',
        name: 'gsbzbxApplication',
        redirect: "/gsbzbxApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApplication'),
        children: [
          {
            path: '/gsbzbxApply1',
            name: 'gsbzbxApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApply1'),
          },
          {
            path: '/gsbzbxApply2',
            name: 'gsbzbxApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApply2'),
          },

        ],
      },
      // 跨境税信保产品申请
      {
        path: 'sxbApplication',
        name: 'sxbApplication',
        redirect: "/sxbApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/shuixinbao/sxbApplication'),
        children: [
          {
            path: '/sxbApply1',
            name: 'sxbApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbApply1.vue'),
          },
          // 税信宝确认授信申请第一步
          {
            path: '/sxbCreditExtension',
            name: 'sxbCreditExtension',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension.vue'),
          },
          // 税信宝确认授信申请第二步
          {
            path: '/sxbCreditExtension2',
            name: 'sxbCreditExtension2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension2.vue'),
          },
          // 税信宝确认授信申请第二步
          {
            path: '/sxbCreditExtension3',
            name: 'sxbCreditExtension3',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension3.vue'),
          },
          {
            path: '/sxbApply2',
            name: 'sxbApply12',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbApply2.vue'),
          },

        ],
      },
      // 普惠驿产品列表
      {
        path: 'BusinessTicketPhy',
        name: 'BusinessTicketPhy',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('../views/puhui/BusinessTicketPhy.vue'),
      },
      {
        path: 'project',
        name: 'project',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('../views/project/project.vue'),
      },
      {
        path: 'projectBusinessTicket',
        name: 'projectBusinessTicket',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectShangPiao/projectBusinessTicket.vue'),
      },
      {
        path: 'projectBusinessDetails',
        name: 'projectBusinessDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('../views/project/projectShangPiao/projectBusinessDetails'),
      },
      {
        path: 'projectDiscountDetails',
        name: 'projectDiscountDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectDiscountDetails'),
      },
      {
        path: 'projectApproveEasypasteDetails',
        name: '商票易贴审批通过',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectApproveEasypasteDetails'),
      },
      {
        path: 'projectPledgeApproveDetalis',
        name: '商票质押审批中',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectPledgeApproveDetalis'),
      },
      {
        path: 'projectApprovePledgeDetails',
        name: '商票质押审批通过',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectApprovePledgeDetails'),
      },
      {
        path: '/projectDetails',
        name: 'projectDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectDetails.vue'),
      },
      // 跨境驿产品详情
      {
        path: '/projectCrossBorder',
        name: 'projectCrossBorder',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectCrossBorder.vue'),
      },
      // 关税E保产品预审详情
      {
        path: '/projectBorderDetails',
        name: 'projectBorderDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetails.vue'),
      },
      // 关税保函产品预审详情
      {
        path: '/projectBorderDetailsGsbh',
        name: 'projectBorderDetailsGsbh',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetailsGsbh.vue'),
      },
      // 税信保产品预审详情
      {
        path: '/projectBorderDetailsSxb',
        name: 'projectBorderDetailsSxb',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetailsSxb.vue'),
      },
      // 正式项目
      // 关税保函产品正式详情
      {
        path: '/regularProjectGsbh',
        name: 'regularProjectGsbh',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectGsbh'),
      },
      // 关税E保产品正式详情
      {
        path: '/regularProjectGseb',
        name: 'regularProjectGseb',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectGseb'),
      },
      // 关税保证保险详情
      {
        path: '/projectGuaranteeDetails',
        name: 'projectGuaranteeDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectGuaranteeDetails'),
      },
      // 税信保产品正式详情
      {
        path: '/regularProjectSxb',
        name: 'regularProjectSxb',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectSxb'),
      },

      // 我的平台
      {
        path: '/platform',
        name: 'platform',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 10,
          auth: true,
        },
        component: () => import('../views/platform/myPlatform.vue'),
      },
      {
        path: '/kaipiao',
        name: 'kaipiao',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 11,
          auth: true,
        },
        component: () => import('../views/platform/kaiPiao.vue'),
      },
      {
        path: '/account',
        name: 'account',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('../views/platform/account.vue'),
      },
      {
        path: '/authentication',
        name: '经办人认证',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('@/views/platform/authentication.vue'),
      }, {
        path: '/authentication1',
        name: '组织信息核验',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('@/views/platform/authentication1.vue'),
      }, {
        path: '/authentication2',
        name: '意愿认证',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('@/views/platform/authentication2.vue'),
      }, {
        path: '/authentication3',
        name: '企业打款',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('@/views/platform/authentication3.vue'),
      },
      {
        path: '/authentication4',
        name: '认证完成',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 12,
          auth: true,
        },
        component: () => import('@/views/platform/authentication4.vue'),
      },
      {
        path: '/communicate',
        name: 'communicate',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 13,
          auth: true,
        },
        component: () => import('../views/platform/communicate.vue'),
      },
      // 合同信息
      {
        path: '/contractMessage',
        name: 'contractMessage',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 13,
          auth: true,
        },
        component: () => import('../views/platform/contractMessage.vue'),
      },
      // 新增认证
      {
        path: '/newlyAttestation',
        name: 'newlyAttestation',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 13,
          auth: true,
        },
        component: () => import('../views/platform/newlyAttestation.vue'),
      },
      // 重新认证
      {
        path: '/updataAttestation',
        name: 'updataAttestation',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 13,
          auth: true,
        },
        component: () => import('../views/platform/updataAttestation.vue'),
      },

    ],
  },

  {
    path: '/loginreg',
    name: '注册页',
    meta: {
      title: "用户登录-数字金融服务平台-商驿科技",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/login/loginreg.vue'),
  },
  {
    path: '/updataPwd',
    name: '忘记密码页',
    meta: {
      title: "用户登录-数字金融服务平台-商驿科技",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/login/updataPwd.vue'),
  },
  {
    path: '/success',
    name: '成功',
    meta: {
      title: "认证结果",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/result/success'),
  },
  {
    path: '/error',
    name: '失败',
    meta: {
      title: "认证结果",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/result/error'),
  },
  {
    path: '/initial',
    name: '等等',
    meta: {
      title: "人脸验证",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/result/initial'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.path === "/login") return next()
  const token = sessionStorage.getItem("token")
  if (to.path === "/loginreg") return next()
  if (to.path === "/updataPwd") return next()
  if (to.path === "/success") return next()
  if (to.path === "/error") return next()
  if (to.path === "/initial") return next()

  if (!token) {
    // setTimeout('console.clear()', 300)
    return next("/login")
  }
  next()
})
export default router
