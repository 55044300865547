/********对服务器端接口以及底层异步请求方法进行二次封装**********/
// 服务器根地址
// export const base = "https://testapi.thunlink.cn:8803";
 // export const base = "http://39.107.49.94:8803";
 export const base = "https://api.thunlink.cn";
//POST请求通用属性/
let postOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
// /api/pros/findSLY 商链数值统计
export const findSLY = async function () {
  let url = base + `/api/pros/findSLY`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 1. JWT登录
// 1-1登录接口
export const userLogin = async function (userNo, password) {
  let url = base + `/api/entlogin/entloginPC`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `userNo=${userNo}&password=${password}`, //请求主体/请求参数
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-2 用户注册 /api/entlogin/reg
export const reg = async function (reg) {
  let { phone, pwd, confirmPwd, msgcode } = reg
  let url = base + `/api/entlogin/reg`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `phoneno=${phone}&newPwd=${pwd}&confirmPwd=${confirmPwd}&msgcode=${msgcode}`, //请求主体/请求参数
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-3 忘记密码 /api/entlogin/updatePwd
export const updatePwd = async function (datas) {
  let { phone, pwd, confirmPwd, msgcode } = datas
  let url = base + `/api/entlogin/updatePwd`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `phoneno=${phone}&newPwd=${pwd}&confirmPwd=${confirmPwd}&msgcode=${msgcode}`, //请求主体/请求参数
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-4获取短信验证码 /api/smsSend
export const smsSend = async function (phoneno) {
  let url = base + `/api/smsSend?phoneNo=${phoneno}`;
  let options = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-2 修改密码接口 /api/entlogin/Pwd
export const updataPwd = async function (form) {
  let { oldpassword, confirmPwd, newpassword } = form
  let url = base + "/api/entlogin/Pwd";
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `oldpassword=${oldpassword}&confirmPwd=${confirmPwd}&newpassword=${newpassword}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-6用户下企业列表  /api/entlogin/userEnt
export const userEnt = async function (userid) {
  let url = base + `/api/entlogin/userEnt?userid=${userid}`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-7点击切换默认企业 /api/entlogin/userupdateCoopid
export const userupdateCoopid = async function (customerid) {
  let url = base + `/api/entlogin/userupdateCoopid?%E4%BC%81%E4%B8%9Aid=${customerid}`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 1-8 点击企业名称更新token /api/entlogin/userEnts
export const userEnts = async function (customerid) {
  let url = base + `/api/entlogin/userEnts?customerid=${customerid}`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 2. 金融服务产品接口  
// 2-0 商链产品列表    /api/products/selectallproduct
export const selectallProduct = async function () {
  let url = base + `/api/products/selectallproduct`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 2-1 首页产品列表    /api/products/selectproducts
export const selectallProducts = async function () {
  let url = base + `/api/products/selectproducts`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 产品详情   /common/getProductDetail
export const getProductDetail = async function (productid) {
  let url = base + `/common/getProductDetail?productid=${productid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// 2-2 点击立即申请跳转申请页，申请页顶部产品接口 /api/products/selectproductsubs
export const selectproductsubs = async function (id) {
  let url = base + `/api/products/selectproductsubs?id=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 产品申请0接口  /api/pros/productsubs0 用于判断用户是否填写了数据，如果有则获取里面的数据回显，用户只需要做修改
export const productsubs0 = async function (id) {
  let url = base + `/api/pros/productsubs0?productid=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    },

  };
  let res = await fetch(url, options);
  let data = await res.json();

  return data;
};
// 2-4 产品申请一   /api/pros/productsubs1
export const productsubs1 = async function (option) {
  let { entscale, totalassets, revenue, liabilities, businessid, productid, productname } = option
  let url = base + `/api/pros/productsubs1?entscale=${entscale}&totalassets=${totalassets}&revenue=${revenue}&liabilities=${liabilities}&businessid=${businessid}&productid=${productid}&productname=${productname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
export const productsubs11 = async function (option) {
  let { entscale, totalassets, revenue, liabilities, productid, productname } = option
  let url = base + `/api/pros/productsubs1?entscale=${entscale}&totalassets=${totalassets}&revenue=${revenue}&liabilities=${liabilities}&productid=${productid}&productname=${productname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 2-5 产品申请二   /api/pros/productsubsacc
export const productsubsacc = async function (creditid) {
  let url = base + `/api/pros/productsubsacc?id=${creditid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },

  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//2-6 /api/pros/productsubsaccadd  Ent产品申请2绑定
export const productsubsaccadd = async function (creditid, ids) {
  let url = base + `/api/pros/productsubsaccadd?id=${creditid}&ids=${ids}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 2-7 邀请客户 产品关联 /api/products/addproductgl
export const addproductgl = async function (Invitecode) {
  let url = base + `/api/products/addproductgl`;
  let options = {
    ...postOptions,
    body: `Invitecode=${Invitecode}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 2-8 提交申请信息 /api/pros/busnessSubmit
export const busnessSubmit = async function (id) {
  let url = base + `/api/pros/busnessSubmit?businessid=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//  2-9 /api/pros/AddBusinessBankInfo  绑定银行卡 
export const AddBusinessBankInfo = async function (params) {
  let { bankentname,
    bankaddress,
    bankaccounts,
    branch,
    linenumber,
    cardno, applyid } = params
  let url = base + `/api/pros/AddBusinessBankInfo`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `bankentname=${bankentname}&bankaddress=${bankaddress}&bankaccounts=${bankaccounts}&branch=${branch}&linenumber=${linenumber}&cardno=${cardno}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 3 申请3 发票
//3-1 获取发票信息接口  /api/Invoice/getInvoice
export const getInvoice = async function (applyid) {
  let url = base + `/api/Invoice/getInvoice?applyid=${applyid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 3-3 删除发票信息接口  ​/api​/Invoice​/delInvoice
export const delInvoice = async function (id) {
  let url = base + `/api/Invoice/delInvoice`;
  let options = {
    ...postOptions,
    body: `id=${id}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//3-4 编辑发票信息接口  /api/Invoice/updateInvoice
export const updateInvoice = async function (prop) {
  let { invCode, contractno, currency, invAmount, invDate, invNo, id } = prop
  let url = base + `/api/Invoice/updateInvoice`;
  let options = {
    ...postOptions,
    body: `id=${id}&currency=${currency}&invAmount=${invAmount}&invDate=${invDate}&invCode=${invCode}&contractno=${contractno}&invNo=${invNo}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 3-5 发票信息详情接口 /api/Invoice/findInvoice

// 4 申请3 合同
// 4-1 获取合同信息接口 /api/TblContract/getContract
export const getContract = async function (applyid) {
  let url = base + `/api/TblContract/getContract?applyid=${applyid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-2 新增合同信息接口 /api/TblContract/addContract
// export const addContract = async function (formContract, applyid) {
//   let { conNo, conName, conAmount, currency } = formContract
//   let url = base + `/api/TblContract/addContract`;
//   let options = {
//     ...postOptions,
//     body: `applyid=${applyid}&conNo=${conNo}&conName=${conName}&conAmount=${conAmount}&currency=${currency}`,
//     headers: {
//       "x-auth": sessionStorage.getItem("token"),
//       "Content-Type": "application/x-www-form-urlencoded",
//     },
//   };
//   let res = await fetch(url, options);
//   let data = await res.json();
//   return data;
// };
// 4-3  新增合同信息接口  /api/TblContract/delContract
export const delContract = async function (id) {
  let url = base + `/api/TblContract/delContract`;
  let options = {
    ...postOptions,
    body: `id=${id}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-3 编辑合同信息接口   /api/TblContract/updateContract
export const updateContract = async function (parameter) {
  let { conName, conAmount, currency, conNo, id } = parameter
  let url = base + `/api/TblContract/updateContract`;
  let options = {
    ...postOptions,
    body: `id=${id}&currency=${currency}&conName=${conName}&conAmount=${conAmount}&conNo=${conNo}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};


// 4 项目管理
// 4-1 授信项目管理表格  /api/pros/findbuss
export const findbuss = async function (pageNum, pageSize, status, productname) {
  let url = base + `/api/pros/findbuss?pageNum=${pageNum}&pageSize=${pageSize}&status=${status}&productname=${productname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//42 用信项目管理 /api/pros/findbussx 
export const findbussx = async function (pageNum, pageSize, status, productname) {
  let url = base + `/api/pros/findbussx`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `pageNum=${pageNum}&pageSize=${pageSize}&status=${status}&productname=${productname}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// 4-2 项目详情顶部接口 /api/pros/findbus
export const findbus = async function (id) {
  let url = base + `/api/pros/findbus?id=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-2 用信项目详情顶部接口 /api/pros/findbuspotout
export const findbuspotout = async function (putoutid) {
  let url = base + `/api/pros/findbuspotout?putoutid=${putoutid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-3用信项目详情下部接口 /api/pros/busapplyputouts
export const busapplyputouts = async function (putoutid) {
  let url = base + `/api/pros/busapplyputouts?putoutid=${putoutid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-3用信项目详情还款 /api/pros/busapplypayment
export const busapplypayment = async function (putoutid) {
  let url = base + `/api/pros/busapplypayment?putoutid=${putoutid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-4 还款申请 /api/pros/Addbusapplypayment 
export const Addbusapplypayment = async function (params) {
  let { corpuspaymethod,
    payacctno,
    payaccountname,
    payamoutn,
    putoutid, } = params
  let url = base + `/api/pros/Addbusapplypayment`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `putoutid=${putoutid}&corpuspaymethod=${corpuspaymethod}&payacctno=${payacctno}&payaccountname=${payaccountname}&payamoutn=${payamoutn}&`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// 4-2 项目详情下面表格接口 /api/pros/findbusapply
export const findbusapply = async function (id) {
  let url = base + `/api/pros/findbusapply?id=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 4-3 /api/pros/Addbusapplyputouts 我的授信 用款申请
export const Addbusapplyputouts = async function (params) {
  let { applyid,
    productcode,
    businesssum,
    termmonth,
    propose } = params
  let url = base + `/api/pros/Addbusapplyputouts`;
  let options = {
    ...postOptions,
    body: `applyid=${applyid}&productcode=${productcode}&businesssum=${businesssum}&termmonth=${termmonth}&propose=${propose}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// 4-3 /api/pros/findbusapplyputout 我的授信 用款信息
export const findbusapplyputout = async function (id) {
  let url = base + `/api/pros/findbusapplyputout`;
  let options = {
    ...postOptions,
    body: `applyid=${id}`,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};





// 5 我的平台
// 5-1 开票信息获取 /api/Coopeorg/listcoopeorg
export const listcoopeorg = async function () {
  let url = base + `/api/Coopeorg/listcoopeorg`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 5-2 开票信息修改 /api/Coopeorg/updatecoopeorg
export const updatecoopeorg = async function (parameter) {
  let { ticketaccountno, ticketaddr, ticketbank, ticketname, ticketNumber, tickettel, customerid } = parameter
  let url = base + `/api/Coopeorg/updatecoopeorg`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `ticketaccountno=${ticketaccountno}&ticketaddr=${ticketaddr}&ticketbank=${ticketbank}&ticketname=${ticketname}&ticketNumber=${ticketNumber}&tickettel=${tickettel}&customerid=${customerid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 5-3 通讯信息获取 /api/Tblcontacts/listTblcontacts
export const listTblcontacts = async function () {
  let url = base + `/api/Tblcontacts/listTblcontacts`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 5-4 通讯信息修改 /api/Tblcontacts/updateTblcontacts
export const updateTblcontacts = async function (parameter) {
  let { contactsname, position, phone, mail, address, customerid } = parameter
  let url = base + `/api/Tblcontacts/updateTblcontacts?contactsname=${contactsname}&position=${position}&phone=${phone}&mail=${mail}&address=${address}&customerid=${customerid}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 5-5 信息认证查询 /api/ents/listent 
export const listent = async function () {
  let url = base + `/api/ents/listent`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 5-6 账号信息查询 /api/users/getUser 
export const getUser = async function () {
  let url = base + `/api/users/getUser`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 6 文件管理
// 6-1 /api/file/getFilePath 获取文件路径列表
export const getFilePath = async function (objectno) {
  let url = base + `/api/file/getFilePath?objectno=${objectno}`;
  let options = {
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 6-2 其他文件删除接口 /api/filess/filedel 
export const filedel = async function (serialno) {
  let url = base + `/api/filess/filedel?%E6%96%87%E4%BB%B6ID=${serialno}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}


// 商票驿接口
// 商票驿产品列表    /api/products/selectallSP
export const selectallSP = async function () {
  let url = base + `/api/products/selectallSP`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    }
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 产品申请0接口 /api/BusinessSPs/productapplys0
export const BusinessSpProductapplys0 = async function (productid) {
  let url = base + `/api/BusinessSPs/productapplys0`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `productid=${productid}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
//Ent商票驿产品列表产品询价第一步 /api/BusinessSPs/findaddbusapplys
export const findaddbusapplys = async function (datas) {
  let { defaulter, enterprise, entoutstanding, entoverdue, entrecords, financing, operating, outrage, outstanding, productcood, productid, productname, punishment, applyId, spid, producttype } = datas
  let url = base + `/api/BusinessSPs/findaddbusapplys`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `defaulter=${defaulter}&enterprise=${enterprise}&entoutstanding=${entoutstanding}&entoverdue=${entoverdue}&entrecords=${entrecords}&financing=${financing}&operating=${operating}&outrage=${outrage}&outstanding=${outstanding}&productcood=${productcood}&productid=${productid}&productname=${productname}&punishment=${punishment}&applyid=${applyId}&spid=${spid}&producttype=${producttype}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 项目管理
//Ent商票驿项目管理 /api/BusinessSPs/ListSP
export const findapplySP = async function (pageNum, pageSize, producttype, status, acceptorname) {
  let url = base + `/api/BusinessSPs/ListSP?pageNum=${pageNum}&pageSize=${pageSize}&producttype=${producttype}&status=${status}&acceptorname=${acceptorname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// Ent商票驿项目管理询价详情 /api/BusinessSPs/findaSP
export const findaSP = async function (spid, sPticketinfoid) {
  let url = base + `/api/BusinessSPs/findaSP`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `spid=${spid}&sPticketinfoid=${sPticketinfoid}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// /api/BusinessSPs/findSP Ent商票驿项目管理 贴现质押详情
export const findSP = async function (spid, applyid) {
  let url = base + `/api/BusinessSPs/findSP`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `spid=${spid}&applyid=${applyid}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//点击按钮显示多条 /api/BusinessSPs/findapplySPlist 
export const findapplySPlist = async function (applyid) {
  let url = base + `/api/BusinessSPs/findapplySPlist `;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `applyid=${applyid}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 询价申请提交    /api/BusinessSPs/busnessSubmit
export const busnessSubmitSP = async function (applyid) {
  let url = base + `/api/BusinessSPs/busnessSubmit`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `%E7%94%B3%E8%AF%B7id=${applyid}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 跨境驿接口
// /api/products/selectkj 跨境驿产品列表
export const selectkj = async function () {
  let url = base + `/api/products/selectkj`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 产品申请第0步 /api/KJApply/kjapply0
export const kjapply0 = async function (procedureId) {
  let url = base + `/api/KJApply/kjapply0`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `%E4%BA%A7%E5%93%81id=${procedureId}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 关税保函产品申请第一步  /api/KJApply/addKJApplys
export const addKJApplys = async function (form) {
  let {
    taxpayerstatus, customsamount, creditrating, highestamount, customs, particularyear, enttariff, currentprofit, paytaxesmoner, periodprofit, productname, productid, productcood, kjid, applyid
  } = form
  let url = base + `/api/KJApply/addKJApplys`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `taxpayerstatus=${taxpayerstatus}&customsamount=${customsamount}&creditrating=${creditrating}&highestamount=${highestamount}&customs=${customs}&particularyear=${particularyear}&enttariff=${enttariff}&currentprofit=${currentprofit}&paytaxesmoner=${paytaxesmoner}&periodprofit=${periodprofit}&productname=${productname}&productid=${productid}&productcood=${productcood}&kjid=${kjid}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 关税保证保险申请第一步  /api/KJApply/addKJApplybzbx
export const addKJApplybzbx = async function (form) {
  let {
    guarantee, particularyear, guaranteedate, currentasset, guaranteeamount, currentliabilities, productname, productid, productcood
  } = form
  let url = base + `/api/KJApply/addKJApplybzbx`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `guarantee=${guarantee}&particularyear=${particularyear}&guaranteedate=${guaranteedate}&currentasset=${currentasset}&guaranteeamount=${guaranteeamount}&currentliabilities=${currentliabilities}&productname=${productname}&productid=${productid}&productcood=${productcood}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 关税E宝产品申请第一步  /api/KJApply/addKJApply
export const addKJApply = async function (form) {
  let {
    entcontrollerneme, particularyear, certid, currentprofit, entcontrollephone, periodprofit, importyears, currentliabilities, taxpayerstatus, periodliabilities, creditrating, currentasset, customs, periodasset, enttariff, highestamount, paytaxesmoner, customsamount, productname, productid, productcood, kjid, applyid
  } = form
  let url = base + `/api/KJApply/addKJApply`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `taxpayerstatus=${taxpayerstatus}&customsamount=${customsamount}&creditrating=${creditrating}&highestamount=${highestamount}&customs=${customs}&particularyear=${particularyear}&enttariff=${enttariff}&currentprofit=${currentprofit}&paytaxesmoner=${paytaxesmoner}&periodprofit=${periodprofit}&entcontrollerneme=${entcontrollerneme}&certid=${certid}&entcontrollephone=${entcontrollephone}&importyears=${importyears}&currentliabilities=${currentliabilities}&periodliabilities=${periodliabilities}&currentasset=${currentasset}&periodasset=${periodasset}&productname=${productname}&productid=${productid}&productcood=${productcood}&kjid=${kjid}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 税信宝申请第一步  /api/KJApply/addKJApplysSXB
export const addKJApplysSXB = async function (form) {
  let { entcontrollerneme, particularyear, certid, currentasset, entcontrollephone, periodasset, drawbackaccount, currenamount, drawbackopening, lastamount, currentvatamount, lastvatamount, productname, productid, productcood } = form
  let url = base + `/api/KJApply/addKJApplysSXB`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `entcontrollerneme=${entcontrollerneme}&particularyear=${particularyear}&certid=${certid}&currentasset=${currentasset}&entcontrollephone=${entcontrollephone}&periodasset=${periodasset}&drawbackaccount=${drawbackaccount}&currenamount=${currenamount}&drawbackopening=${drawbackopening}&lastamount=${lastamount}&lastvatamount=${lastvatamount}&currentvatamount=${currentvatamount}&productname=${productname}&productid=${productid}&productcood=${productcood}`
  };
  let res = await fetch(url, options);
  let data = await res.json();

  return data;
};
// 税信宝确认授信第一步  /api/KJApply/updateKJApplySXB
export const updateKJApplySXB = async function (form) {
  let { entcontrollerneme, lastamount, certid, lastvatamount, entcontrollephone, lastlastamount, agentname, lastlastvatamount, agentid, particularyear, agentphone, currentasset, drawbackaccount, periodasset, drawbackopening, currentoperatingreceipt, currenamount, lastoperatingreceipt, currentvatamount, productname, productid, productcood, applyid } = form
  let url = base + `/api/KJApply/updateKJApplySXB`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `entcontrollerneme=${entcontrollerneme}&lastamount=${lastamount}&certid=${certid}&lastvatamount=${lastvatamount}&entcontrollephone=${entcontrollephone}&lastlastamount=${lastlastamount}&agentname=${agentname}&lastlastvatamount=${lastlastvatamount}&agentid=${agentid}&particularyear=${particularyear}&agentphone=${agentphone}&currentasset=${currentasset}&drawbackaccount=${drawbackaccount}&periodasset=${periodasset}&drawbackopening=${drawbackopening}&currentoperatingreceipt=${currentoperatingreceipt}&currenamount=${currenamount}&lastoperatingreceipt=${lastoperatingreceipt}&currentvatamount=${currentvatamount}&productname=${productname}&productid=${productid}&productcood=${productcood}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();

  return data;
};
// 提交申请信息 /api/KJApply/busnessSubmits
export const busnessSubmits = async function (procedureId) {
  let url = base + `/api/KJApply/busnessSubmits`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `%E7%94%B3%E8%AF%B7id=${procedureId}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 税信宝提交申请信息 /api/KJApply/busnessSubmitsSXB
export const busnessSubmitsSXB = async function (procedureId, applyid) {
  let url = base + `/api/KJApply/busnessSubmitsSXB`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `businessid=${procedureId}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//项目管理列表 /api/KJApply/findkjapply
export const findkjapply = async function (pageNum, pageSize, status, productname) {
  let url = base + `/api/KJApply/findkjapply?pageNum=${pageNum}&pageSize=${pageSize}&status=${status}&productname=${productname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// 产品详情详情   /api/KJApply/findkj
export const findkj = async function (applyid) {
  let url = base + `/api/KJApply/findkj`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 确认授信数据回显   /api/KJApply/findupdateKJApply
export const findupdateKJApply = async function (applyid) {
  let url = base + `/api/KJApply/findupdateKJApply`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
//关税保函确认授信数据提交 /api/KJApply/updateKJApply
export const updateKJApply = async function (form) {
  let {
    taxpayerstatus, customsamount, creditrating, highestamount, customs, particularyear, enttariff, currentprofit, paytaxesmoner, periodprofit, productname, productid, productcood, kjid, applyid
  } = form
  let url = base + `/api/KJApply/updateKJApply`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `taxpayerstatus=${taxpayerstatus}&customsamount=${customsamount}&creditrating=${creditrating}&highestamount=${highestamount}&customs=${customs}&particularyear=${particularyear}&enttariff=${enttariff}&currentprofit=${currentprofit}&paytaxesmoner=${paytaxesmoner}&periodprofit=${periodprofit}&productname=${productname}&productid=${productid}&productcood=${productcood}&kjid=${kjid}&applyid=${applyid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};

// /api/KJApply/findkjgs  关税保函正式项目
export const findkjgs = async function (applyid) {
  let url = base + `/api/KJApply/findkjgs`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// /api/KJApply/findkjs 关税E宝正式项目
export const findkjs = async function (applyid) {
  let url = base + `/api/KJApply/findkjs`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 签署合同
// /api/TblContract/Contract 合同签署
export const Contract = async function (parameter) {
  let { entname, FRaccountid, inputdate, JBRaccountid, licenseno, mainindname, officer, officertel, ORGaccountid, productcode, radio, ticketadder, ticketbank, ticketbankno, tickethead, ticketno, tickettel, step, applyid } = parameter
  let url = base + `/api/TblContract/Contract`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `entname=${entname}&FRaccountid=${FRaccountid}&inputdate=${inputdate}&JBRaccountid=${JBRaccountid}&licenseno=${licenseno}&mainindname=${mainindname}&officer=${officer}&officertel=${officertel}&ORGaccountid=${ORGaccountid}&productcode=${productcode}&radio=${radio}&ticketadder=${ticketadder}&ticketbank=${ticketbank}&ticketbankno=${ticketbankno}&tickethead=${tickethead}&ticketno=${ticketno}&tickettel=${tickettel}&step=${step}&applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 查看协议 /api/TblContract/createContractReview
export const createContractReview = async function (parameter) {
  let { entname, FRaccountid, inputdate, JBRaccountid, licenseno, mainindname, officer, officertel, ORGaccountid, productcode, radio, ticketadder, ticketbank, ticketbankno, tickethead, ticketno, tickettel, step, applyid } = parameter
  let url = base + `/api/TblContract/createContractReview`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `entname=${entname}&FRaccountid=${FRaccountid}&inputdate=${inputdate}&JBRaccountid=${JBRaccountid}&licenseno=${licenseno}&mainindname=${mainindname}&officer=${officer}&officertel=${officertel}&ORGaccountid=${ORGaccountid}&productcode=${productcode}&radio=${radio}&ticketadder=${ticketadder}&ticketbank=${ticketbank}&ticketbankno=${ticketbankno}&tickethead=${tickethead}&ticketno=${ticketno}&tickettel=${tickettel}&step=${step}&applyid=${applyid}`
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// /api/TblContract/Contracts 获取合同信息
export const Contracts = async function (parameter) {
  let { productcode, entname } = parameter
  let url = base + `/api/TblContract/Contracts?productcode=${productcode}&entname=${entname}`;
  let options = {
    ...postOptions,
    headers: {
      "x-auth": sessionStorage.getItem("token"),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  let res = await fetch(url, options);
  let data = await res.json();
  return data;
};
// 认证
// 9-0-0 /api/ents/addRZentFind  人脸结果查询
export const addRZentFind = async function () {
  let url = base + `/api/ents/addRZentFind`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 9-1  /api/ents/addSJHent 手机号认证
export const addSJHent = async function (parameter) {
  let { authcode, phoneIdNumber, idType, phoneMobile, phoneName, flowId } = parameter
  let url = base + `/api/ents/addSJHent`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `authcode=${authcode}&idNumber=${phoneIdNumber}&idType=${idType}&mobile=${phoneMobile}&name=${phoneName}&flowId=${flowId}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 获取验证码 /api/ents/addYHKent
export const addYHKent = async function (params) {
  let { cardName, cardIdNumber, cardBankno, cardMobile, idType, customerid } = params
  let url = base + `/api/ents/addYHKent`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `name=${cardName}&idNumber=${cardIdNumber}&bankno=${cardBankno}&mobile=${cardMobile}&idType=${idType}&coopid=${customerid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 9-6  /api/ents/addentYJRZ   一键认证
export const addentYJRZ = async function () {
  let url = base + `/api/ents/addentYJRZ`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },

  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 9-4  /api/ents/addentPhone   意愿认证-法定代表人手机号认证获取验证码
export const addentPhoneCode = async function (parameter) {
  let { accountid, idNumber, idType, mobile, name, fraccountid } = parameter
  let url = base + `/api/ents/addentPhone`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `idNumber=${idNumber}&idType=${idType}&accountid=${fraccountid}&mobile=${mobile}&name=${name}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 9-7 /api/ents/addentQYDK 企业打款认证1 
export const addentQYDK = async function (parameter) {
  let { agentAccountId, accountId, frAuthEnable, repetition } = parameter
  let url = base + `/api/ents/addentQYDK`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `agentAccountId=${agentAccountId}&accountId=${accountId}&frAuthEnable=${frAuthEnable}&repetition=${repetition}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 9-8 /api/ents/addentQYDKs 企业打款认证2
export const addentQYDKs = async function (parameter) {
  let { bank, subbranch, province, city, cnapsCode, cardNo, flowId } = parameter
  let url = base + `/api/ents/addentQYDKs`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `bank=${bank}&subbranch=${subbranch}&province=${province}&city=${city}&cnapsCode=${cnapsCode}&cardNo=${cardNo}&flowId=${flowId}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
//9-9  /api/ents/addentQYDKJE   企业打款验证
export const addentQYDKJE = async function (parameter) {
  let { flowId, amount } = parameter
  let url = base + `/api/ents/addentQYDKJE`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `amount=${amount}&flowId=${flowId}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
//9-9  /api/ents/H5RZentFind   人脸认证
export const H5RZentFind = async function (thirdPartyUserId) {
  let url = base + `/api/ents/H5RZentFind`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `thirdPartyUserId=${thirdPartyUserId}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}

// /api/Contract/Contract 合同签署结果查询
export const ContractContract = async function (flowId) {
  let url = base + `/api/Contract/Contract`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `flowId=${flowId}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/Contract/subbranch 获取联行号
export const subbranch = async function (flowId, keyWord) {
  let url = base + `/api/Contract/subbranch`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `flowId=${flowId}&keyWord=${keyWord}`,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/SysNewInfo/findSysNewInfo 获取资讯信息
export const findSysNewInfo = async function () {
  let url = base + `/api/SysNewInfo/findSysNewInfo`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/TblContract/ContractList 获取合同列表查询
export const ContractList = async function (pageNum, pageSize) {
  let url = base + `/api/TblContract/ContractList`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `pageNum=${pageNum}&pageSize=${pageSize}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 认证信息回显
// /api/ents/entHX 获取合同列表查询
export const entHX = async function (customerid) {
  let url = base + `/api/ents/entHX `;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `customerid=${customerid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/BusinessSPs/findSPTJ 首页数值统计
export const findSPTJ = async function () {
  let url = base + `/api/BusinessSPs/findSPTJ`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/BusinessSPs/findSPJE 商票数值统计
export const findSPJE = async function () {
  let url = base + `/api/BusinessSPs/findSPJE`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/KJApply/findZSSJ 跨境数值统计
export const findZSSJ = async function () {
  let url = base + `/api/KJApply/findZSSJ`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/KJApply/updateapplyid 跨境合同签署修改applyid
export const updateapplyid = async function (applyid, flowid) {
  let url = base + `/api/KJApply/updateapplyid`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `applyid=${applyid}&flowid=${flowid}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// /api/TblContract/ContractLists  点击出现甲乙公司名称 
export const ContractLists = async function (id) {
  let url = base + `/api/TblContract/ContractLists`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: `contractid=${id}`
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// 批量删除 /api/BusinessSPs/delectPJs
export const delectPJs = async function (id) {
  let url = base + `/api/BusinessSPs/delectPJs?pjids=${id}`;
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
  };
  console.log(url);
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
// ----------------------------------------------------------------------
// 商票驿新增需求
// post方法
export const myPostFetch = async function (url, params) {
  url = base + url;
  // let params = { userNo: userNo, password: password }
  let u = "";
  if (params) {
    let paramsArray = [];
    //拼接参数  
    Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
    if (url.search(/\?/) === -1) {
      u += paramsArray.join('&')
    } else {
      u += '&' + paramsArray.join('&')
    }
  }
  let options = {
    ...postOptions,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "x-auth": sessionStorage.getItem("token"),
    },
    body: u,
  };
  let res = await fetch(url, options);
  let data = await res.json();
  return data;
}
