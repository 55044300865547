<template>
  <div class="box">
    <el-container class="home-container">
      <el-header>
        <div class="logo">
          <router-link to="/home">
            <img src="@/assets/PC/首页/logo10拷贝.png" alt="" />
          </router-link>
        </div>

        <!-- 导航栏开始 -->
        <div class="navigation">
          <ul>
            <li :class="{ active: row == 1 }" @click="goHome">首页</li>
            <li :class="{ active: row == 2 }" @click="goBusiness">金融服务</li>
            <li :class="{ active: row == 3 }" @click="goProject">项目管理</li>
            <li :class="{ active: row == 4 }" @click="goPlatform">用户中心</li>
          </ul>
        </div>
        <!-- 导航栏结束 -->
        <!-- user用户信息 -->
        <div class="userinfo">
          <!-- 下拉 -->
          <el-dropdown>
            <span class="el-dropdown-link">
              <img class="icon" src="@/assets/PC/首页/组15.png" alt="" />
            </span>
            <!-- <span class="el-dropdown-link">
              <i class="el-icon-arrow-right el-icon--right"></i>
            </span> -->
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="updatePwd()">
                <img
                  style="width: 16px; height: 16px"
                  src="@/assets/PC/首页/组60.png"
                  alt=""
                />
                修改密码</el-dropdown-item
              >
              <el-dropdown-item @click.native="open">
                <img
                  style="width: 16px; height: 16px"
                  src="@/assets/PC/首页/组61.png"
                  alt=""
                />
                退出系统</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <div class="chen">
            <!-- 下拉 -->
            <!-- 手机号 -->
            <h4 class="entname" v-if="entname != 'null'">
              {{ userPhone }}
            </h4>
            <el-dropdown v-if="entname == 'null'">
              <span class="el-dropdown-link">
                <div class="userinfo-info" style="display: flex">
                  <h4 class="entname">{{ userPhone }}</h4>
                  <i
                    class="el-icon-arrow-right el-icon--right"
                    style="margin-top: 6px"
                  ></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- || rzstatus != 'succ' -->
                <div
                  class="approve"
                  v-if="coopid == 'null' || rzstatus != 'succ'"
                >
                  <p>您未完成认证</p>
                  <div class="approve-rz">
                    <i
                      class="el-icon-edit-outline"
                      style="font-size: 16px; margin-right: 5px"
                    ></i>
                    <span @click="updateCoopeorgInvoice()">立即认证</span>
                  </div>
                </div>
                <div class="approve" v-else>
                  <template v-if="ents">
                    <div class="firm" v-if="ent">
                      <p @click="updataToken(ent.customerid, ent.entname)">
                        {{ ent.entname }}
                      </p>
                      <span>当前默认</span>
                    </div>
                    <div class="firm" v-for="item in ents" :key="item.entname">
                      <p
                        style="color: #808080"
                        @click="updataToken(item.customerid, item.entname)"
                      >
                        {{ item.entname }}
                      </p>
                      <span
                        @click="upDefault(item.customerid)"
                        style="color: #48a0b8; border-color: #48a0b8"
                        >默认设置</span
                      >
                    </div>
                    <div class="approve-rz">
                      <i
                        class="el-icon-edit-outline"
                        style="font-size: 16px; margin-right: 5px"
                      ></i>
                      <span @click="updateCoopeorgInvoice()">新增认证</span>
                    </div>
                  </template>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-else>
              <span class="el-dropdown-link">
                <div class="userinfo-info" style="display: flex">
                  <!-- 企业名称 -->
                  <h3>
                    {{ entname }}
                  </h3>
                  <i
                    class="el-icon-arrow-right el-icon--right"
                    style="margin-top: 9px"
                  ></i>
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="approve" v-if="rzstatus !== 'succ'">
                  <p>您未完成认证</p>
                  <div class="approve-rz">
                    <i
                      class="el-icon-edit-outline"
                      style="font-size: 16px; margin-right: 5px"
                    ></i>
                    <span @click="updateCoopeorgInvoice()">立即认证</span>
                  </div>
                </div>
                <div class="approve" v-else>
                  <template v-if="ents">
                    <div class="firm" v-if="ent">
                      <p @click="updataToken(ent.customerid, ent.entname)">
                        {{ ent.entname }}
                      </p>
                      <span>当前默认</span>
                    </div>
                    <div class="firm" v-for="item in ents" :key="item.entname">
                      <p
                        style="color: #808080"
                        @click="updataToken(item.customerid, item.entname)"
                      >
                        {{ item.entname }}
                      </p>
                      <span
                        @click="upDefault(item.customerid, item.entname)"
                        style="color: #48a0b8; border-color: #48a0b8"
                        >默认设置</span
                      >
                    </div>
                    <div class="approve-rz">
                      <i
                        class="el-icon-edit-outline"
                        style="font-size: 16px; margin-right: 5px"
                      ></i>
                      <span @click="updateCoopeorgInvoice()">新增认证</span>
                    </div>
                  </template>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-header>
      <el-container>
        <el-aside>
          <img
            class="kefu"
            src="../assets/PC/新增补充切图/01登录+首页/组53.png"
            alt=""
          />
          <!-- 首页 -->
          <ul v-if="row == 1">
            <li class="aside">
              <div :class="{ asideActive: row == 1 }">业务总览</div>
            </li>
          </ul>
          <!-- 金融服务 -->
          <ul v-if="row == 2">
            <li class="aside" @click="goBusiness()">
              <div :class="{ asideActive: financialRow == 1 }">商链驿</div>
            </li>
            <li class="aside" @click="shangPiao()">
              <div :class="{ asideActive: financialRow == 2 }">商票驿</div>
            </li>
            <li class="aside" @click="kuaJing()">
              <div :class="{ asideActive: financialRow == 3 }">跨境驿</div>
            </li>
            <!-- financialRow = 4 -->
            <li class="aside" @click="puhui()">
              <div :class="{ asideActive: financialRow == 4 }">普惠驿</div>
            </li>
          </ul>
          <!-- 项目管理 -->
          <ul v-if="row == 3">
            <li class="aside" @click="goProject()">
              <div :class="{ asideActive: projectRow == 1 }">商链驿</div>
            </li>
            <li class="aside" @click="projectShangPiao()">
              <div :class="{ asideActive: projectRow == 2 }">商票驿</div>
            </li>
            <li class="aside" @click="projectKuaJing()">
              <div :class="{ asideActive: projectRow == 3 }">跨境驿</div>
            </li>
            <li class="aside" @click="projectRow = 4">
              <div :class="{ asideActive: projectRow == 4 }">普惠驿</div>
            </li>
          </ul>
          <!-- 我的平台-->
          <ul v-if="row == 4">
            <li class="aside" @click="goPlatform()">
              <div :class="{ asideActive: platformRow == 1 }">平台认证</div>
            </li>
            <li class="aside" @click="goKaiPiao()">
              <div :class="{ asideActive: platformRow == 2 }">开票信息</div>
            </li>
            <li class="aside" @click="goAccount()">
              <div :class="{ asideActive: platformRow == 3 }">账号信息</div>
            </li>
            <li class="aside" @click="goCommunicate()">
              <div :class="{ asideActive: platformRow == 4 }">通讯信息</div>
            </li>
            <li class="aside" @click="goContractMessage()">
              <div :class="{ asideActive: platformRow == 5 }">合同信息</div>
            </li>
          </ul>
        </el-aside>
        <el-main>
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </el-main>
        <el-footer
          ><p>
            <span>Copyright © {{ date }} 北京商驿科技有限公司</span>
          </p></el-footer
        >
      </el-container>
    </el-container>
    <!-- 退出登录弹框 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="32%">
      <!-- 内容区域 -->
      <div class="dialog-center">
        <p>
          <img src="@/assets/PC/新增补充切图/02我的平台/组69.png" alt="" />
          您确定要退出系统吗？
        </p>
      </div>
      <!-- 按钮区域 -->
      <div slot="footer" class="dialog-footer">
        <button
          class="dialog-footer-bnt btn1"
          @click="centerDialogVisible = false"
        >
          取 消
        </button>
        <button class="dialog-footer-bnt btn2" @click="quit()">确 定</button>
      </div>
    </el-dialog>
    <!-- 修改密码弹框 -->
    <el-dialog
      class="password"
      title="修改密码"
      @close="close"
      :closeOnClickModal="false"
      :visible.sync="updateoPwdVisible"
      width=""
    >
      <!-- 内容区域 -->
      <div class="dialog-center">
        <ul>
          <li>
            <el-form
              ref="form"
              :model="form"
              :rules="formotherRules"
              label-width="100px"
            >
              <el-form-item label="原密码" prop="oldpassword">
                <el-input
                  v-model="form.oldpassword"
                  placeholder="请输入原密码"
                  type="password"
                  @blur="oldpassworda"
                ></el-input>
                <p class="error" v-show="oldPwd">原密码错误</p>
              </el-form-item>
            </el-form>
          </li>
          <li>
            <el-form
              ref="form"
              :model="form"
              :rules="formotherRules"
              label-width="100px"
            >
              <el-form-item label="新密码" prop="confirmPwd">
                <el-input
                  v-model="form.confirmPwd"
                  type="password"
                  placeholder="请输入新密码，8-16位字母+数字组合"
                  @blur="b"
                ></el-input>
                <p class="error" v-show="confirmPwd">字母+数字组合，8-16位</p>
              </el-form-item>
            </el-form>
          </li>
          <li>
            <el-form
              ref="form"
              :model="form"
              :rules="formotherRules"
              label-width="100px"
            >
              <el-form-item label="确认新密码" prop="newpassword">
                <el-input
                  type="password"
                  v-model="form.newpassword"
                  placeholder="请确认新密码"
                  @blur="newpassword"
                ></el-input>
                <p class="error" v-show="newPwd">两次密码不一致，请重新输入</p>
              </el-form-item>
            </el-form>
          </li>
        </ul>
      </div>
      <!-- 按钮区域 -->
      <!--  -->
      <!-- -->
      <div slot="footer" class="dialog-footer">
        <button
          class="dialog-footer-bnt btn1"
          @click="updateoPwdVisible = false"
        >
          取 消
        </button>
        <button class="dialog-footer-bnt btn2" @click="updatePWD()">
          确 定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { userEnt, userupdateCoopid, updataPwd, userEnts } from "@/utils";

export default {
  data() {
    return {
      userPhone: "",
      entname: "",
      coopid: "",
      rzstatus: "",
      date: "",
      centerDialogVisible: false, //退出登录弹框状态变量
      updateoPwdVisible: false, //修改密码弹框状态变量
      row: "1", //上侧导航栏状态变量
      financialRow: "1", // 金融服务状态变量
      projectRow: "1", // 项目管理状态变量
      platformRow: "1", //我的平台状态变量
      ent: null, //当前默认认证企业
      ents: null, //设置默认认证企业
      a: "",
      oldPwd: null,
      confirmPwd: null,
      newPwd: null,
      form: {
        oldpassword: "", //旧密码
        confirmPwd: "", //新密码
        newpassword: "", //确认新密码
      },
      // 表单验证
      formotherRules: {
        oldpassword: [{ required: true, message: " ", trigger: "blur" }],
        confirmPwd: [{ required: true, message: " ", trigger: "blur" }],
        newpassword: [{ required: true, message: " ", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["id"]),
  },
  mounted() {
    this.coopid = sessionStorage.getItem("coopid");
    this.entname = sessionStorage.getItem("entname");
    this.userPhone = sessionStorage.getItem("userPhone");
    this.rzstatus = sessionStorage.getItem("rzstatus");
    if (this.id) {
      this.a = this.id;
    }
    this.updataActive();
    // 金融服务
    this.updataBusiness();
    // 项目管理
    this.updataProject();
    // 我的平台
    this.updataAside();
    // 认证状态
    this.status();
    //  获取当前年份
    this.getDate();
  },
  methods: {
    // 关闭弹窗
    close() {
      this.form.oldpassword = "";
      this.form.confirmPwd = "";
      this.form.newpassword = "";
    },
    // 获取当前年份
    getDate() {
      let date = new Date();
      this.date = date.getFullYear();
    },
    // 下拉
    // 修改密码
    updatePwd() {
      this.updateoPwdVisible = true;
    },
    // 校验原密码
    oldpassworda() {
      this.oldPwd = false;
    },
    // 校验新密码
    b() {
      this.confirmPwd = false;
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
      if (!reg.test(this.form.confirmPwd)) {
        this.form.confirmPwd = "";
        this.confirmPwd = true;
      }
    },
    // 确认新密码
    newpassword() {
      this.newPwd = false;
      if (this.form.confirmPwd != this.form.newpassword) {
        this.form.newpassword = "";
        this.newPwd = true;
      }
    },
    // 点击退出弹框按钮确认键
    quit() {
      this.$router.push("/login");
      sessionStorage.clear();
      setTimeout(() => {
        this.$router.go(0);
      }, 500);
    },
    // 点击退出按钮
    open() {
      this.centerDialogVisible = true;
    },
    // 跳转至平台认证页
    updateCoopeorgInvoice() {
      this.$router.push("/authentication");
      this.setTime();
    },
    setTime() {
      setTimeout(() => {
        this.$router.go(0);
      }, 200);
    },
    // 点击企业名称更新token
    async updataToken(customerid, entname) {
      let data = await userEnts(customerid);
      if (data.msg == "ok") {
        sessionStorage.setItem("token", data.data);
        this.$store.commit("updateToken", data.data);
        sessionStorage.setItem("customerid", customerid);
        this.$store.commit("updataCustomerid", customerid);
        sessionStorage.setItem("entname", entname);
        this.$store.commit("updateEntname", entname);
        this.$router.push("/");
        this.$Message.success("切换成功");
        this.setTim();
      }
    },
    // 点击确认按钮
    async updatePWD() {
      if (
        (this.form.oldpassword.trim() != "") &
        (this.form.confirmPwd.trim() != "") &
        (this.form.newpassword.trim() != "") &
        (this.form.confirmPwd == this.form.newpassword)
      ) {
        let data = await updataPwd(this.form);
        if (data.code == 330) {
          this.oldPwd = 1;
        } else if (data.code == 99) {
          this.confirmPwd = 1;
        } else if (data.code == 310) {
          this.newPwd = 1;
        } else if (data.code == 100) {
          this.$Message.success("密码修改成功");
          this.updateoPwdVisible = false;
          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
          setTimeout(() => {
            this.$router.go(0);
          }, 1500);
        }
      } else {
        this.$Message.error("两次密码不一致，请重新输入");
      }
    },
    // 认证状态列表
    async status() {
      if (this.coopid == "null" || this.rzstatus != "succ") {
        return;
      } else {
        let { data } = await userEnt(this.a);
        let ents = [];
        if (data.ent) {
          data.ents.forEach((item) => {
            if (item.entname !== data.ent.entname) {
              ents.push(item);
            }
          });
          sessionStorage.setItem("entname", data.ent.entname);
          this.$store.commit("updateUserName", data.ent.entname);
          this.ent = data.ent;
          this.ents = ents;
        } else {
          this.ents = data.ents;
        }
      }
    },
    // 点击切换默认企业
    async upDefault(customerid, entname) {
      let data = await userupdateCoopid(customerid);
      if (data.msg == "ok") {
        sessionStorage.setItem("entname", entname);
        this.$store.commit("updateEntname", entname);
        this.$Message.success("当前默认设置成功");
        this.$router.push("/");
        this.setTim();
      }
    },
    // 跳转至首页
    goHome() {
      this.row = 1;
      if (this.$route.path == "/home") return;
      this.$router.push("/home");
    },
    // 跳转至金融服务
    goBusiness() {
      this.row = 2;
      this.financialRow = 1;
      if (this.$route.path == "/business") return;
      this.$router.push("/business");
    },
    // 金融服务商票驿
    shangPiao() {
      this.financialRow = 2;
      if (this.$route.path == "/businessTicket") return;
      this.$router.push("/businessTicket");
    },
    // 金融服务跨境驿
    kuaJing() {
      this.financialRow = 3;
      if (this.$route.path == "/crossBorder") return;
      this.$router.push("/crossBorder");
    },
    // 金融服务普惠驿
    puhui() {
      this.financialRow = 4;
      if (this.$route.path == "/BusinessTicketPhy") return;
      this.$router.push("/BusinessTicketPhy");
    },
    // 跳转至项目管理页
    goProject() {
      this.projectRow = 1;
      this.row = 3;
      if (this.$route.path == "/project") return;
      this.$router.push("/project");
    },
    // 项目管理商票驿模块
    projectShangPiao() {
      this.projectRow = 2;
      if (this.$route.path == "/projectBusinessTicket") return;
      this.$router.push("/projectBusinessTicket");
    },
    projectKuaJing() {
      this.projectRow = 3;
      if (this.$route.path == "/projectCrossBorder") return;
      this.$router.push("/projectCrossBorder");
    },
    // 跳转至我的平台
    goPlatform() {
      this.row = 4;
      this.platformRow = 1;
      if (this.$route.path == "/platform") return;
      this.$router.push("/platform");
    },
    // 跳转至开票信息页
    goKaiPiao() {
      this.platformRow = 2;
      if (this.$route.path == "/kaipiao") return;
      this.$router.push("/kaipiao");
    },
    // 跳转至账号信息页
    goAccount() {
      this.platformRow = 3;
      if (this.$route.path == "/account") return;
      this.$router.push("/account");
    },
    // 跳转至通讯信息页
    goCommunicate() {
      this.platformRow = 4;
      if (this.$route.path == "/communicate") return;
      this.$router.push("/communicate");
    },
    // 跳转至合同信息页
    goContractMessage() {
      this.platformRow = 5;
      if (this.$route.path == "/contractMessage") return;
      this.$router.push("/contractMessage");
    },
    // 保证页面刷新之后，选中项在当前页面侧边栏上
    updataActive() {
      if (this.$route.path == "/home") {
        this.row = 1;
      } else if (
        this.$route.path == "/business" ||
        this.$route.path == "/procedure1" ||
        this.$route.path == "/procedure2" ||
        this.$route.path == "/procedure3" ||
        this.$route.path == "/procedure4" ||
        this.$route.path == "/procedure5" ||
        this.$route.path == "/applyForMoney1" ||
        this.$route.path == "/applyForMoney2" ||
        this.$route.path == "/businessTicket" ||
        this.$route.path == "/immediatelyEnquiry" ||
        this.$route.path == "/enquiry" ||
        this.$route.name == "enquiryApply1" ||
        this.$route.path == "/enquiryApply2" ||
        this.$route.path == "/enquiryApply3" ||
        this.$route.path == "/enquiryPledgeApply" ||
        this.$route.path == "/crossBorder" ||
        this.$route.path == "/borderApplication" ||
        this.$route.path == "/crossBorderApply1" ||
        this.$route.path == "/crossBorderApply2" ||
        this.$route.path == "/gsbhApplication" ||
        this.$route.path == "/gsbhApply1" ||
        this.$route.path == "/gsbhApply2" ||
        this.$route.path == "/gsbzbxApplication" ||
        this.$route.path == "/gsbzbxApply1" ||
        this.$route.path == "/gsbzbxApply2" ||
        this.$route.path == "/creditExtension" ||
        this.$route.path == "/sxbApplication" ||
        this.$route.path == "/sxbApply1" ||
        this.$route.path == "/sxbApply2" ||
        this.$route.path == "/sxbCreditExtension" ||
        this.$route.path == "/sxbCreditExtension2" ||
        this.$route.path == "/sxbCreditExtension3" ||
        this.$route.path == "/BusinessTicketPhy" ||
        this.$route.path == "/applyForMoney1" ||
        this.$route.path == "/billAdministration"
      ) {
        this.row = 2;
      } else if (
        this.$route.path == "/project" ||
        this.$route.path == "/projectDetails" ||
        this.$route.path == "/projectBusinessTicket" ||
        this.$route.path == "/projectDiscountDetails" ||
        this.$route.path == "/projectBusinessDetails" ||
        this.$route.path == "/projectCrossBorder" ||
        this.$route.path == "/projectBorderDetails" ||
        this.$route.path == "/projectGuaranteeDetails" ||
        this.$route.path == "/projectBorderDetailsGsbh" ||
        this.$route.path == "/regularProjectGsbh" ||
        this.$route.path == "/regularProjectGseb" ||
        this.$route.path == "/projectBorderDetailsSxb" ||
        this.$route.path == "/regularProjectSxb" ||
        this.$route.path == "/applyForMoneyDetails" ||
        this.$route.path == "/projectPledgeApproveDetalis" ||
        this.$route.path == "/projectApprovePledgeDetails" ||
        this.$route.path == "/projectDiscountDetails" ||
        this.$route.path == "/projectApproveEasypasteDetails"
      ) {
        this.row = 3;
      } else if (
        this.$route.path == "/platform" ||
        this.$route.path == "/account" ||
        this.$route.path == "/kaipiao" ||
        this.$route.path == "/communicate" ||
        this.$route.path == "/newlyAttestation" ||
        this.$route.path == "/updataAttestation" ||
        this.$route.path == "/contractMessage" ||
        this.$route.path == "/authentication" ||
        this.$route.path == "/authentication1" ||
        this.$route.path == "/authentication2" ||
        this.$route.path == "/authentication3" ||
        this.$route.path == "/authentication4"
      ) {
        this.row = 4;
      }
    },
    // 金融服务
    updataBusiness() {
      if (
        this.$route.path == "/businessTicket" ||
        this.$route.path == "/immediatelyEnquiry" ||
        this.$route.path == "/enquiry" ||
        this.$route.name == "enquiryApply1" ||
        this.$route.path == "/enquiryApply2" ||
        this.$route.path == "/enquiryApply3" ||
        this.$route.path == "/enquiryPledgeApply" ||
        this.$route.path == "/billAdministration"
      ) {
        this.financialRow = 2;
      } else if (
        this.$route.path == "/crossBorder" ||
        this.$route.path == "/borderApplication" ||
        this.$route.path == "/crossBorderApply1" ||
        this.$route.path == "/crossBorderApply2" ||
        this.$route.path == "/gsbhApplication" ||
        this.$route.path == "/gsbhApply1" ||
        this.$route.path == "/gsbhApply2" ||
        this.$route.path == "/creditExtension" ||
        this.$route.path == "/sxbApplication" ||
        this.$route.path == "/sxbApply1" ||
        this.$route.path == "/sxbApply2" ||
        this.$route.path == "/sxbCreditExtension" ||
        this.$route.path == "/sxbCreditExtension2" ||
        this.$route.path == "/sxbCreditExtension3" ||
        this.$route.path == "/gsbzbxApply1" ||
        this.$route.path == "/gsbzbxApply2" ||
        this.$route.path == "/gsbzbxApplication"
      ) {
        this.financialRow = 3;
      } else if (this.$route.path == "/BusinessTicketPhy") {
        this.financialRow = 4;
      }
    },
    // 项目管理
    updataProject() {
      if (
        this.$route.path == "/projectBusinessTicket" ||
        this.$route.path == "/projectDiscountDetails" ||
        this.$route.path == "/projectBusinessDetails" ||
        this.$route.path == "/projectPledgeApproveDetalis" ||
        this.$route.path == "/projectApprovePledgeDetails" ||
        this.$route.path == "/projectApproveEasypasteDetails" ||
        this.$route.path == "/billAdministration"
      ) {
        this.projectRow = 2;
      } else if (
        this.$route.path == "/projectCrossBorder" ||
        this.$route.path == "/projectBorderDetails" ||
        this.$route.path == "/projectGuaranteeDetails" ||
        this.$route.path == "/projectBorderDetailsGsbh" ||
        this.$route.path == "/regularProjectGsbh" ||
        this.$route.path == "/regularProjectGseb" ||
        this.$route.path == "/projectBorderDetailsSxb" ||
        this.$route.path == "/regularProjectSxb"
      ) {
        this.projectRow = 3;
      }
    },
    // 我的平台
    updataAside() {
      if (
        this.$route.path == "/platform" ||
        this.$route.path == "/newlyAttestation" ||
        this.$route.path == "/updataAttestation" ||
        this.$route.path == "/authentication" ||
        this.$route.path == "/authentication1" ||
        this.$route.path == "/authentication2" ||
        this.$route.path == "/authentication3" ||
        this.$route.path == "/authentication4"
      ) {
        this.platformRow = 1;
      } else if (this.$route.path == "/kaipiao") {
        this.platformRow = 2;
      } else if (this.$route.path == "/account") {
        this.platformRow = 3;
      } else if (this.$route.path == "/communicate") {
        this.platformRow = 4;
      } else if (this.$route.path == "/contractMessage") {
        this.platformRow = 5;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
}

.home-container {
  height: 100%;
}
// 头部区域
.el-header {
  z-index: 99999;
  width: 100vw;
  position: fixed;
  background-color: #fff;
  z-index: 999;
  height: 80px !important;
  @media only screen and (min-width: 1580px) and (max-width: 1950px) {
    height: 80px !important;
  }
  padding: 0;
  box-shadow: 3px 0px 9px 0px rgba(178, 178, 178, 0.46);
  // logo
  .logo {
    width: 94px;
    height: 34px;
    margin: 24px 68px;
    img {
      width: 100%;
    }
  }
  // 导航栏
  .navigation {
    position: absolute;
    top: 10px;
    left: 200px;
    width: 500px;
    height: 60px;
    ul {
      height: 60px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      li {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75px;
        height: 45px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        &.active {
          color: #48a0b8;
          border-bottom: 2px solid #48a0b8;
        }
      }
    }
  }

  // 用户信息
  .userinfo {
    display: flex;
    position: absolute;
    align-items: center;
    top: 20px;
    right: 32px;
    // width: 16%;
    height: 50px;
    .icon {
      width: 40px;
      height: 40px;
      margin-right: 11px;
    }
    .chen {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      .entname {
        // 小屏
        @media only screen and (min-width: 1px) and (max-width: 1100px) {
          display: none;
        }
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #3095ae;
        margin-bottom: 3px;
      }
      .userinfo-info {
        margin-top: -4px;
        cursor: pointer;
        h3 {
          // 小屏
          @media only screen and (min-width: 1px) and (max-width: 1100px) {
            display: none;
          }
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #3095ae;
          margin-top: 2px;
          .btn {
            width: 80px;
            height: 20px;
            margin-left: 20px;
            border-radius: 5px;
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #3095ae;
            background-color: rgba(110, 214, 242, 0.2);
            border: 1px solid #3095ae;
          }
        }
        h4 {
          font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #333333;
        }
        .entname {
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #3095ae;
          margin-bottom: 3px;
        }
      }
    }
  }
}
// 认证信息
.approve {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  width: 360px;
  // height: 80px;
  .firm {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    margin-top: 15px;
    cursor: pointer;
    p {
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 300;
      color: #1a1a1a;
    }
    span {
      height: 20px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 300;
      border-bottom: 1px solid #333;
      color: #333333;
      margin-top: -2px;
    }
  }
  p {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #808080;
  }
  .approve-rz {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 28px;
    background: #48a0b8;
    border-radius: 4px;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #fff;
    margin-top: 15px;
    cursor: pointer;
  }
}

// 侧边栏
.el-aside {
  position: fixed;
  height: 100vh;
  width: 180px !important;
  @media only screen and (min-width: 1580px) and (max-width: 1950px) {
    width: 210px !important;
  }
  background-color: #fafeff;
  margin-top: 80px;
  z-index: 999;
  .aside {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 62px;
    font-size: 17px;
    font-family: Source Han Sans SC;
    font-weight: 4500;
    color: #48a0b8;
    cursor: pointer;
    .asideActive {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 62px;
      border-left: 3px solid #3095ae;
      background: rgba(110, 214, 242, 0.2);
    }
  }
  .kefu {
    position: fixed;
    width: 189px;
    height: 56px;
    bottom: 60px;
  }
}

// 主体区域
.el-main {
  background-color: #f0f1f5;
  // height: 88vh;
  // overflow: auto;
  padding: 100px 20px 20px 200px;
  @media only screen and (min-width: 1580px) and (max-width: 1950px) {
    padding: 100px 20px 20px 230px;
  }
  p {
    position: absolute;
    width: 85%;
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #b3b3b3;
    text-align: center;
  }
}
// 底部区域
.el-footer {
  position: relative;
  display: flex;
  justify-content: center;
  height: 32px !important;
  background-color: #f0f1f5;
  margin-left: 180px;
  @media only screen and (min-width: 1580px) and (max-width: 1950px) {
    margin-left: 210px;
  }
  p {
    position: absolute;
    top: -8px;
    font-size: 16px;
  }
}
// // 切换过渡
.fade-enter {
  opacity: 0;
}

.fade-leave {
  opacity: 1;
}

.fade-enter-active {
  transition: opacity 0.3s;
}

.fade-leave-active {
  opacity: 0;

  transition: opacity 0.3s;
}
// 弹出框
.dialog-center {
  width: 90%;
  text-align: center;
  overflow: hidden;
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-left: 53px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #4d4d4d;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-evenly;
  width: 70%;
  margin: 0 auto;
  z-index: 9999;
  .dialog-footer-bnt {
    width: 121px;
    height: 31px;
    color: #48a0b8;
    border: 1px solid #56b1ca;
    border-radius: 4px;
    background-color: #fff;
    z-index: 9999;
  }
  .btn2 {
    color: #fff;
    background-color: #58b2cb;
    z-index: 9999;
  }
  .abc {
    width: 368px;
    .el-select {
      width: 100%;
    }
  }
}
.el-form-item {
  margin-bottom: 16px;
  height: 45px;
  .error {
    height: 15px;
    line-height: 15px;
    font-size: 13px;
    color: #f56c6c;
    position: absolute;
    left: -30px;
    @media only screen and (min-width: 1580px) and (max-width: 1950px) {
      left: 31px;
    }
  }
}
::v-deep .password .el-dialog {
  overflow: auto;
  @media only screen and (min-width: 1px) and (max-width: 899px) {
    width: 32%;
  }
  // 小屏
  @media only screen and (min-width: 900px) and (max-width: 1535px) {
    width: 32%;
  }
  // 中屏
  @media only screen and (min-width: 1536px) and (max-width: 1919px) {
    width: 27%;
  }
  // 大屏
  @media only screen and (min-width: 1920px) and (max-width: 2580px) {
    width: 27%;
  }
}
/* 输入框样式  */
::v-deep .el-input__inner {
  box-sizing: border-box;
  width: 400px;
  @media only screen and (min-width: 1px) and (max-width: 999px) {
    width: 100px;
  }
  @media only screen and (min-width: 1000px) and (max-width: 1349px) {
    width: 200px;
  }
  @media only screen and (min-width: 1350px) and (max-width: 1535px) {
    width: 280px;
  }
  @media only screen and (min-width: 1535px) and (max-width: 1920px) {
    width: 300px;
  }

  height: 39px;
  font-size: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 0px !important;
  padding: 10px 5px;
  color: #606266;
  outline: 0;
}
</style>
<style>
.el-dialog--center .el-dialog__body {
  padding: 25px 32px 25px;
}
.el-dialog__header {
  border-bottom: 1px solid #e7e7e7;
}
.el-dialog {
  border-radius: 8px;
  /* @media only screen and (min-width: 1580px) and (max-width: 1950px) {
    width: 28%;
  } */
}
</style>
