import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("token"),
    entname: sessionStorage.getItem("entname"),
    userName: sessionStorage.getItem("userName"),
    rzstatus: sessionStorage.getItem("rzstatus"),
    id: sessionStorage.getItem("id"),
    coopid: sessionStorage.getItem("coopid"),
    customerid: sessionStorage.getItem("customerid"),
    userPhone: sessionStorage.getItem("userPhone"),
    date: sessionStorage.getItem("date"),
    // 产品编号
    productcode: "",
    // 商链申请地址栏参数
    shangLianApplyParameter: {
      procedureid: localStorage.getItem("SLProcedureid"),
      productname: localStorage.getItem("SLProductname"),
      creditid: localStorage.getItem("SLCreditid"),
      applyid: localStorage.getItem("SLApplyid"),
      productcode: localStorage.getItem("SLProductcode"),
      status: localStorage.getItem("SLStatus"),
      putoutid: localStorage.getItem("SLPutoutid")


    },
    // 商票申请地址栏参数
    shangPiaoApplyParameter: {
      applyid: localStorage.getItem("applyid"),
      productcode: localStorage.getItem("productcode"),
      productname: localStorage.getItem("productname"),
      show01: localStorage.getItem("show01"),
      id: localStorage.getItem("id"),
      radio: localStorage.getItem("radio"),
    },
    // 商票询价/正式申请地址栏参数
    shangPiaoDetailsParameter: {
      ticketinfoid: localStorage.getItem("ticketinfoid"),
      id: localStorage.getItem("detailsId"),
      spid: localStorage.getItem("spid"),
      // status: localStorage.getItem("status"),
    },
  },
  getters: {
  },
  mutations: {
    // 修改商链申请地址栏参数
    updataSLApplyid(state, payload) {
      state.shangLianApplyParameter.applyid = payload
      localStorage.setItem("SLApplyid", payload)
    },
    updataSLStatus(state, payload) {
      state.shangLianApplyParameter.status = payload
      localStorage.setItem("SLStatus", payload)
    },
    updataSLProductcode(state, payload) {
      state.shangLianApplyParameter.productcode = payload
      localStorage.setItem("SLProductcode", payload)
    },
    updataSLProcedureid(state, payload) {
      state.shangLianApplyParameter.procedureid = payload
      localStorage.setItem("SLProcedureid", payload)
    },
    updataSLPutoutid(state, payload) {
      state.shangLianApplyParameter.putoutid = payload
      localStorage.setItem("SLPutoutid", payload)
    },
    updataSLProductname(state, payload) {
      state.shangLianApplyParameter.productname = payload
      localStorage.setItem("SLProductname", payload)
    },
    updataSLCreditid(state, payload) {
      state.shangLianApplyParameter.creditid = payload
      localStorage.setItem("SLCreditid", payload)
    },
    // 修改商票申请地址栏参数
    // applyid
    updataApplyApplyid(state, payload) {
      state.shangPiaoApplyParameter.applyid = payload
      localStorage.setItem("applyid", payload)
    },
    // 产品编号
    updataApplyProductcode(state, payload) {
      state.shangPiaoApplyParameter.productcode = payload
      localStorage.setItem("productcode", payload)
    },
    // 产品名称
    updataApplyProductname(state, payload) {
      state.shangPiaoApplyParameter.productname = payload
      localStorage.setItem("productname", payload)
    },
    // 产品码值
    updataApplyShow01(state, payload) {
      state.shangPiaoApplyParameter.show01 = payload
      localStorage.setItem("show01", payload)

    },
    // 产品id
    updataApplyId(state, payload) {
      state.shangPiaoApplyParameter.id = payload
      localStorage.setItem("id", payload)

    },
    // 产品利率
    updataApplyRadio(state, payload) {
      state.shangPiaoApplyParameter.radio = payload
      localStorage.setItem("radio", payload)
    },
    // 修改商票询价/正式申请地址栏参数
    updataDetailsTicketinfoid(state, payload) {
      state.shangPiaoDetailsParameter.ticketinfoid = payload
      localStorage.setItem("ticketinfoid", payload)
    },
    updataDetailsId(state, payload) {
      state.shangPiaoDetailsParameter.id = payload
      localStorage.setItem("detailsId", payload)
    },
    updataDetailsSpid(state, payload) {
      state.shangPiaoDetailsParameter.spid = payload
      localStorage.setItem("spid", payload)
    },
    // updataDetailsStatus(state, payload) {
    //   state.shangPiaoDetailsParameter.status = payload
    //   localStorage.setItem("status", payload)
    // },










    // 修改产品编号

    updateToken(state, payload) {
      state.token = payload
    },

    updateUserName(state, payload) {
      state.userName = payload
    },

    updateEntname(state, payload) {
      state.entname = payload
    },
    upRzstatus(state, payload) {
      state.rzstatus = payload
    },
    coopid(state, payload) {
      state.coopid = payload
    },
    id(state, payload) {
      state.id = payload
    },
    updataCustomerid(state, payload) {
      state.customerid = payload
    },
    updataUserPhone(state, payload) {
      state.userPhone = payload
    },
    updataDate(state, payload) {
      state.date = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
